export const banksInIndia = [
  "State Bank of India (SBI)",
  "HDFC Bank",
  "ICICI Bank",
  "Punjab National Bank (PNB)",
  "Axis Bank",
  "Bank of Baroda (BoB)",
  "Kotak Mahindra Bank",
  "IndusInd Bank",
  "Union Bank of India",
  "Canara Bank",
  "IDFC First Bank",
  "Yes Bank",
  "Bank of India (BoI)",
  "Indian Bank",
  "Central Bank of India",
  "UCO Bank",
  "IDBI Bank",
  "Federal Bank",
  "RBL Bank",
  "South Indian Bank",
  "Karur Vysya Bank",
  "DCB Bank",
  "Punjab and Sind Bank",
  "Jammu & Kashmir Bank",
  "Syndicate Bank",
  "Allahabad Bank",
  "Dhanlaxmi Bank",
  "Andhra Bank",
  "Vijaya Bank",
  "Bandhan Bank",
  "City Union Bank",
  "Lakshmi Vilas Bank",
  "Tamilnad Mercantile Bank",
  "Nainital Bank",
  "Suryoday Small Finance Bank",
  "Equitas Small Finance Bank",
  "Ujjivan Small Finance Bank",
  "ESAF Small Finance Bank",
  "AU Small Finance Bank",
  "Jana Small Finance Bank",
  "Fincare Small Finance Bank",
  "North East Small Finance Bank",
  "Utkarsh Small Finance Bank",
  "Capital Small Finance Bank",
  "Shivalik Small Finance Bank",
  "India Post Payments Bank",
  "Paytm Payments Bank",
  "Airtel Payments Bank",
  "Fino Payments Bank",
  "NSDL Payments Bank",
];
